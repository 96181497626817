<template>
  <div class="text-sm">
    <div class="flex ">
      
      <div
        class=""
        style="width: 200px"
      >
        <div class="flex items-center">
          <div
            class="text-blue-600 font-bold text-base "    
          >
            {{ name }}
          </div>
          <div class="text-sm ml-1">様</div>
        </div>
        <div v-for="(ai, i) in additional_info"
          :key="i"
          class=" text-left text-sm"
        >
          {{ ai }}
        </div>
      </div>
      <div
        class=" flex-wrap  ml-2 "
        style="max-width: 200px"
        v-if="tags"
      >
        <div
          v-for="(t, j) in tags"
          :key="j"
          :style="{ 'background-color': t.bgcolor, 'color': t.fontcolor }"
          class="ml-1 mb-1 px-2 text-xs py-1 bg-blue-600 rounded text-white whitespace-nowrap"
        >
          {{ t.name }}
        </div>
      </div>
      <!-- <div class="ml-10 text-right">
        <svg
          class="w-6 h-6 ml-auto"
          xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
        </svg>
      </div> -->
    </div>
    
    <div class="py-2 text-xs">
      <div class="mb-1 flex items-center">
        <div
          :class="{ 'bg-green-600 text-white': visitor_benefit_redeemed, 'bg-gray-400 text-gray-200': !visitor_benefit_redeemed }"
          class="flex items-center rounded px-2  "
        >
          <div class="px-1 py-1">
            <svg
              v-if="visitor_benefit_redeemed"
              class="w-4"
              xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
            </svg>
            <svg
              v-else
              xmlns="http://www.w3.org/2000/svg"
              class="w-4 " viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8 7a1 1 0 00-1 1v4a1 1 0 001 1h4a1 1 0 001-1V8a1 1 0 00-1-1H8z" clip-rule="evenodd" />
            </svg>
          </div>
          <div class="ml-1">
            来場<br>
            特典
          </div>
        </div>
        <div class="ml-2 ">
          {{ visitor_benefit }}
        </div>
      </div>
      <div class="flex items-center">
        <div
          :class="{ 'bg-green-600 text-white': contract_benefit_redeemed, 'bg-gray-400 text-gray-200': !contract_benefit_redeemed }"
          
          class="flex items-center rounded px-2  "
        >
          <div class="px-1 py-1">
            <svg
              v-if="contract_benefit_redeemed"
              class="w-4"
              xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
            </svg>
            <svg
              v-else
              xmlns="http://www.w3.org/2000/svg"
              class="w-4" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8 7a1 1 0 00-1 1v4a1 1 0 001 1h4a1 1 0 001-1V8a1 1 0 00-1-1H8z" clip-rule="evenodd" />
            </svg>
          </div>
          <div class="ml-1">
            成約<br>
            特典
          </div>
        </div>
        <div class="ml-2 ">
          {{ contract_benefit }}
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, SetupContext, } from 'vue';

export default defineComponent({
  props: {
    name: String,
    tags: Array,
    visitor_benefit: String,
    visitor_benefit_redeemed: Boolean,
    contract_benefit: String,
    contract_benefit_redeemed: Boolean,
    additional_info: Array
  },
  setup(_, context: SetupContext) {
    // const headers = g
    return {
    }
  }
})
</script>