<template>
  <div class="p-3">
    <div class="flex mb-5 text-sm">
      <div class="flex items-center">
        <a
          :class="{ 'bg-blue-400 text-white': statusQuery == null || statusQuery == 'all' }"
          :href="`${route.path}?status=all`"
        >全て</a>
        <div class="vl ml-2"></div>
        <a
          :href="`${route.path}?status=inprogress`"
          class="ml-2"
          :class="{ 'bg-blue-400 text-white': statusQuery == 'inprogress' }"
        >未完了のみ</a>
        <div class="vl ml-2"></div>
        <a
          :href="`${route.path}?status=done`"
          class="ml-2"
          :class="{ 'bg-blue-400 text-white': statusQuery == 'done' }"
        >完了のみ</a>
      </div>
      <div class="ml-5 flex items-center ">
        <div>検索ワード</div>
        <text-field
          class="ml-2"
          v-model="searchWord"
        ></text-field>
        <!-- <button class="bg-white py-1 ml-2 shadow">検索</button> -->
      </div>
    </div>
    <div class="flex mb-2">
      検討内容：
      <div
        v-for="(p, i) in purposeList"
        :key="i"
        :class="{ 'ml-1': i > 0 }"
        class="rounded-full text-xs shadow py-1 px-2"
        :style="`background-color: ${p.bgcolor}; color: ${p.fontcolor}`"
      >{{ p.name }}</div>
    </div>
    <div class="flex mb-5">
      <div>オーナ種類：</div>
      <div
        v-for="(ot, i) in ownerTypeList"
        :key="i"
        :class="{ 'ml-1': i > 0 }"
        class="rounded shadow text-xs px-2 py-1"
        :style="`background-color: ${ot.bgcolor}; color: ${ot.fontcolor}`"
      >{{ ot.name }}</div>
    </div>
    <div class="text-right py-2 flex">
      <button
        @click="addProposal"
        class="success flex justify-center"
      >
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd" />
        </svg>
        <div>新しい案件を追加する</div>
      </button>
      <div class="flex ml-auto text-sm">
        <div class="flex items-center">
          <div
            class="inline-block p-2 rounded w-6 h-6 rounded-full" style="background-color: lightgray"
          >
            
          </div>
          <div class="ml-2">完了</div>
        </div>
        <div class="flex items-center ml-2">
          <div
            class="inline-block p-2 rounded w-6 h-6 rounded-full" style="background-color: white"
          >
            
          </div>
          <div class="ml-2">進行中</div>
        </div>
        <div class="flex items-center ml-2">
          <div
            class="inline-block p-2 rounded w-6 h-6 rounded-full" style="background-color: lightyellow"
          >
            
          </div>
          <div class="ml-2">承認待ち</div>
        </div>
      </div>
    </div>
    
    <!-- <div
      class="flex mb-3"
    >
      
      <status-flow
        v-if="dataReady"
        class="flex-grow"
        :proposalStatus="statusList"
      ></status-flow>
      <div
        class="mt-auto ml-2"
      >
        
      </div>
    </div> -->
    <div class="overflow-x-auto">
      <table class="base text-sm" v-if="dataReady">
        <thead>
          <tr class="">
            <th class="">紹介ID</th>
            <th>検討内容</th>
            <th>紹介されたお客様</th>
            <th>紹介したオーナー様</th>
            <th>ステータス</th>
            <th>進行スケジュール</th>
            <th>編集</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(p, i) in displayedData"
            :key="i"
            :class="{ 'done-row': p.is_done, 'approving-row': p.waiting_approval }"
          >
            <td>
              {{ p.id }}
            </td>
            <td>
              <div class="inline-block rounded-full shadow px-2 py-1 text-xs whitespace-nowrap" :style="{ 'background-color': p.purpose.bgcolor, 'color': p.purpose.fontcolor }">
                  {{ p.purpose.name }}
                
              </div>
            </td>
            <td>
              <list-table-profile
                :name="p.client_name"
                :tags="p.relation ? [{ ...p.relation, name: p.relation.relation }] : []"
                :visitor_benefit="p.client_visitor_benefit.benefit"
                :contract_benefit="p.client_contract_benefit.benefit"
                :visitor_benefit_redeemed="p.client_visitor_benefit_redeemed"
                :contract_benefit_redeemed="p.client_contract_benefit_redeemed"
                :additional_info="Array.from({ length: 3 }).map((v, i) => p[`sync_service${i + 1}_text`])"
              ></list-table-profile>
            </td>
            <td>
              <list-table-profile
                :name="p.owner_name"
                :tags="p.owner_type ? [p.owner_type] : []"
                :visitor_benefit="p.owner_visitor_benefit.benefit"
                :contract_benefit="p.owner_contract_benefit.benefit"
                :visitor_benefit_redeemed="p.owner_visitor_benefit_redeemed"
                :contract_benefit_redeemed="p.owner_contract_benefit_redeemed"
                :additional_info="Array.from({ length: 3 }).map((v, i) => p[`sync_service${i + 4}_text`])"
                            
              ></list-table-profile>
            </td>
            <td class="whitespace-nowrap">
              {{ p.status ? p.status.name : '-' }}
            </td>
            <td>
              <div class="flex items-center text-xs">
                <div
                  v-for="(s, i) in p.history.filter(s => s.before_status  )"
                  :key="i"
                  style="width: 50px"
                >
                  <div
                    :style="`background-color: ${s.before_status.color}; width: 50px`"
                    class="table-cell align-middle px-1 rounded-lg text-white h-24"
                    
                  >
                    <div>
                      {{ s.before_status.tag }}
                    </div>
                    <div style="font-size: 11px">
                      {{ s.before_status.name }}
                    </div>
                    
                  </div>
                  <!-- <div
                    class="bg-blue-600 table-cell align-middle w-16 px-2 rounded-lg text-white h-24"
                    v-else>
                    開始
                  </div> -->
                  <table class="w-full text-xs text-left">
                    <tbody>
                      <tr>
                        <td class="text-center">{{ formatDate(s.transition_date, 'M/D') }}</td>
                      </tr>
                      <!-- <tr>
                        <th>終了</th>
                        <td>{{ p.history[i] ? formatDate(p.history[i].transition_date, 'M/D') : 'Null' }}</td>
                      </tr> -->
                      
                    </tbody>
                   
                  </table>
                  
                </div>
                <div
                  v-if="p.status"
                  class="text-xs"
                  style="width: 50px"
                >
                  
                  <div
                    :style="`background-color: ${p.status.color}; width: 50px`"
                    class=" table-cell align-middle px-1 rounded-lg text-white h-24"
                  >
                    <div
                      style="font-size: 10px"
                      class="bg-red-600 rounded text-white"
                      v-if="p.waiting_approval">
                      承認待ち
                    </div>
                    <div>
                      {{ p.status.tag }}
                    </div>
                    <div style="font-size: 11px">
                      {{ p.status.name }}
                    </div>
                  </div>
                  <table class="w-full text-xs text-left">
                    <tbody>
                      <tr>
                        <td class="text-center">{{ formatDate(p.history[p.history.length - 1].transition_date, 'M/D') }}</td>
                      </tr>
                      <!-- <tr>
                        <th>終了</th>
                        <td>現在</td>
                      </tr> -->
                      
                    </tbody>
                   
                  </table>
                  
                </div>
                <div
                  @click="statusEditingItem = p"
                  v-if="!p.waiting_approval && (p.history.length == 0 || p.next_status.length)"
                  class="cursor-pointer flex items-center rounded-full bg-gray-600 text-white inline-block py-2 px-4 ml-1"
                >
                  <div class="text-xs">
                    次へ
                  </div>
                  
                </div>
                <button
                  @click="() => { router.push({ name: 'ProposalsDetailApproval', params: { id: p.id } }) }"
                  v-if="p.waiting_approval && p.purpose.approved_by.id == profile.id"
                  class="text-xs whitespace-nowrap cursor-pointer flex items-center rounded-full bg-red-600 font-bold shadow text-white inline-block py-2 px-4 ml-1 text-xs"
                >
                  承認
                </button>
                
              </div>
            </td>
            <td class="text-right">
              
              <router-link
                :to="{ name: 'ProposalsDetailEdit', params: { id: p.id } }"
                v-slot="{ href, navigate }"
              >
                <svg
                  :href="href"
                  @click="navigate"
                  
                  class="inline-block cursor-pointer w-6"
                  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                </svg>
                
              </router-link>
              <svg
                @click="onDelete(p.id)"
                xmlns="http://www.w3.org/2000/svg"
                class="inline-block cursor-pointer h-5 w-5" 
                viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
              </svg>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <frame-modal
      v-if="statusEditingItem != null"
    >
     
      <status-history-form
        :statusOptions="statusEditingItem.next_status"
        :proposalId="statusEditingItem.id"
        :currentStatusId="statusEditingItem.status ? statusEditingItem.status.id : null"
        @close="statusEditingItem = null"
        @change="onChangeStatus"
      >
      
      </status-history-form>
    </frame-modal>
  </div>
</template>
<style scoped lang="scss">
  
  .vl {
    border-left: 1px solid black;
    height: 20px;
  }
  .done-row > th,
  .done-row > td {
    background-color: lightgray;
  }
  .approving-row > th,
  .approving-row > td {
    background-color: lightyellow;
  }
  

</style>
<script lang="ts">
import { computed, defineComponent, SetupContext, ref, onMounted, registerRuntimeCompiler } from 'vue';

import { IOwnerRelation, IOwnerType, IProposal, IProposalStatus, IProposalStatusHistory, IPurpose, } from '@/types/Interfaces';

import StatusFlow from './StatusFlow.vue';
import StatusHistoryForm from './StatusHistoryForm.vue';

import useProposal from '@/types/Proposal';
import useProposalStatus from '@/types/ProposalStatus';
import useProposalStatusHistory from '@/types/ProposalStatusHistory';

import usePurpose from '@/types/Purpose';
import useOwnerType from '@/types/OwnerType';

import ListTableProfile from './ListTableProfile.vue';
import { cloneDeep, conformsTo, result, sortedLastIndex } from 'lodash';
import { useRoute, useRouter } from 'vue-router';

import {formatDate} from '@/utils';
import useOwnerRelation from '@/types/OwnerRelation';
import ProposalsVue from '../Proposals.vue';
import useFlashMessage from '@/types/FlashMessage';
import useAuth from '@/types/Auth';

export default defineComponent({
  components: {
    // StatusFlow,
    ListTableProfile,
    StatusHistoryForm
  },
  setup(_, context: SetupContext) {
    const router = useRouter();
    const route = useRoute();
    
    const statusQuery = route.query.status?.toString() || 'all';
    const searchWord = ref('');
    const dataReady = ref(false);

    const {
      profile    
    } = useAuth();

    const {
      setMessage  
    } = useFlashMessage()

    const {
      fetchPurposes
    } = usePurpose();

    const {
      fetchOwnerTypes
    } = useOwnerType();
    
    const {
      fetchOwnerRelations
    } = useOwnerRelation()
    
    const {
      fetchProposals,
      deleteProposal
    }  = useProposal();

    const {
      fetchProposalStatus,
      
    } = useProposalStatus();

    const {
      createProposalStatusHistory,
      // fetchProposalStatusHistory
    } = useProposalStatusHistory();
    
    const data = ref<IProposal[]>([]);
    const displayedData = computed(() => {
      if (searchWord.value == '') return data.value;
      return data.value.filter((p: IProposal) => {
        return p.client_name.includes(searchWord.value.trim())
            || p.owner_name.includes(searchWord.value.trim())
      })
    })
    const statusList = ref<IProposalStatus[]>([]);
    // const history = ref<IProposalStatusHistory[]>([]);
    const purposeList = ref<IPurpose[]>([]);
    const ownerTypeList = ref<IOwnerType[]>([]);
    const ownerRelationList = ref<IOwnerRelation[]>([])

    onMounted(() => {
      Promise.all([
        fetchProposals({ status: statusQuery }),
        fetchProposalStatus(),
        fetchPurposes(),
        fetchOwnerRelations(),
        fetchOwnerTypes(),
      ]).then(([proposals, statusData, purposeData, relationData, ownerTypeData]) => {
        dataReady.value = true;
        data.value = proposals;
        console.log(data.value)
        statusList.value = statusData;
        purposeList.value = purposeData;
        ownerRelationList.value = relationData;
        ownerTypeList.value = ownerTypeData;
      }).catch(err => {
        console.error(err)
      })
    })
    // data.value = fetchProposals();
    // statusList.value = fetchProposalStatus();
    // history.value = fetchProposalStatusHistory();
    // purposeList.value = fetchPurposes();
    // ownerTypeList.value = await fetchOwnerTypes();

    // const getStatusHistory = (proposalId: number) => {

    //   const historys = history.value.filter(h => h.proposal == proposalId).map((h: any) => {
    //     const s = statusList.value.find((s: any) => s.id === h.before_status);
    //     if (s == null) return null;
    //     return {
    //       transition_date: h.transition_date,
    //       status: s
    //     }
    //   }).filter(e => e);
      
    //   return historys;
    // };

    // const getChildren = (s: IProposalStatus) => {
    //   return status.filter((d: any) => d.parent === s.tag);
    // };

    const statusEditingItem = ref<IProposal | null>(null);
    
    
    // const getNextStatuses = (proposalId: number, strStatus: string) => {
    //   const status: IProposalStatus|undefined = statusList.value.find((s: IProposalStatus) => {
    //     return s.name == strStatus
    //   });
    //   console.log(status)
    //   if (status == null) return [];
    //   const children = statusList.value.filter((s: IProposalStatus) => s.parent == status.tag);
    //   return children;
    // };

    // const statusEditingItemNextStatus = computed(() => {
    //   if (statusEditingItem.value == null || statusEditingItem.value.id == null) return null;
    //   return getNextStatuses(statusEditingItem.value.id, statusEditingItem.value.status);
    // });

    const addProposal = () => {
      router.push({ name: 'ProposalsDetailEdit', params: { id: 'new' } })
    }

    const onChangeStatus = async (payload: any) => {
      try {
        const data = await createProposalStatusHistory(payload);
        setMessage({ type: 'success', message: 'ステータスを更新しました。' })
      } catch (err) {
        console.error(err)
        
      }
      
      statusEditingItem.value = null;
      // data.value = await fetchProposals();
      data.value = await fetchProposals();
      //history.value = await fetchProposalStatusHistory()
    }

   

    // const getOwnerTypes = (otList: string[]) => {
      
    //   return otList.map((otStr: string) => {
    //     return ownerTypeList.value.find((ot: IOwnerType) => ot.name == otStr)  
    //   }).filter(e => e)
    // };

    // const getRelations = (reList: string[]) => {
    //   console.log(ownerRelationList.value)
    //   const data = reList.map((orStr: string) => {
    //     const data = ownerRelationList.value.find((or: IOwnerRelation) => or.relation == orStr);
    //     if (data == null ) return null;
    //     return data;
    //   }).filter(e => e).map((d: any)=> ({ name: d.relation, bgcolor: 'gray', fontcolor: 'white' }))
    //   console.log(data)
    //   return data;

    // };

    const getPurpose = (pStr: string) => {
      console.log(pStr)
      const p = purposeList.value.find((p: IPurpose) => p.name == pStr)  
      console.log(p)
      return p;
    }

    const onDelete = async (propsalId: number) => {
      if (!confirm('削除しますか？')) return;
      await deleteProposal(propsalId);
      data.value = await fetchProposals(route.query);
      setMessage({ type: 'success', message: '削除しました。' })
    }

    
    

    return {
      route,
      router,
      profile,
      searchWord,
      formatDate,
      statusQuery,
      dataReady,
      addProposal,
      data,
      displayedData,
      // getStatusHistory,
      statusEditingItem,
      purposeList,
      statusList,
      ownerTypeList,
      onChangeStatus,
      // getOwnerTypes,
      // getRelations,
      getPurpose,
      onDelete,
    }
  }
})
</script>