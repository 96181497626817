
import { computed, defineComponent, SetupContext, } from 'vue';

export default defineComponent({
  props: {
    name: String,
    tags: Array,
    visitor_benefit: String,
    visitor_benefit_redeemed: Boolean,
    contract_benefit: String,
    contract_benefit_redeemed: Boolean,
    additional_info: Array
  },
  setup(_, context: SetupContext) {
    // const headers = g
    return {
    }
  }
})
