
import { computed, defineComponent, SetupContext, ref, onMounted, registerRuntimeCompiler } from 'vue';

import { IOwnerRelation, IOwnerType, IProposal, IProposalStatus, IProposalStatusHistory, IPurpose, } from '@/types/Interfaces';

import StatusFlow from './StatusFlow.vue';
import StatusHistoryForm from './StatusHistoryForm.vue';

import useProposal from '@/types/Proposal';
import useProposalStatus from '@/types/ProposalStatus';
import useProposalStatusHistory from '@/types/ProposalStatusHistory';

import usePurpose from '@/types/Purpose';
import useOwnerType from '@/types/OwnerType';

import ListTableProfile from './ListTableProfile.vue';
import { cloneDeep, conformsTo, result, sortedLastIndex } from 'lodash';
import { useRoute, useRouter } from 'vue-router';

import {formatDate} from '@/utils';
import useOwnerRelation from '@/types/OwnerRelation';
import ProposalsVue from '../Proposals.vue';
import useFlashMessage from '@/types/FlashMessage';
import useAuth from '@/types/Auth';

export default defineComponent({
  components: {
    // StatusFlow,
    ListTableProfile,
    StatusHistoryForm
  },
  setup(_, context: SetupContext) {
    const router = useRouter();
    const route = useRoute();
    
    const statusQuery = route.query.status?.toString() || 'all';
    const searchWord = ref('');
    const dataReady = ref(false);

    const {
      profile    
    } = useAuth();

    const {
      setMessage  
    } = useFlashMessage()

    const {
      fetchPurposes
    } = usePurpose();

    const {
      fetchOwnerTypes
    } = useOwnerType();
    
    const {
      fetchOwnerRelations
    } = useOwnerRelation()
    
    const {
      fetchProposals,
      deleteProposal
    }  = useProposal();

    const {
      fetchProposalStatus,
      
    } = useProposalStatus();

    const {
      createProposalStatusHistory,
      // fetchProposalStatusHistory
    } = useProposalStatusHistory();
    
    const data = ref<IProposal[]>([]);
    const displayedData = computed(() => {
      if (searchWord.value == '') return data.value;
      return data.value.filter((p: IProposal) => {
        return p.client_name.includes(searchWord.value.trim())
            || p.owner_name.includes(searchWord.value.trim())
      })
    })
    const statusList = ref<IProposalStatus[]>([]);
    // const history = ref<IProposalStatusHistory[]>([]);
    const purposeList = ref<IPurpose[]>([]);
    const ownerTypeList = ref<IOwnerType[]>([]);
    const ownerRelationList = ref<IOwnerRelation[]>([])

    onMounted(() => {
      Promise.all([
        fetchProposals({ status: statusQuery }),
        fetchProposalStatus(),
        fetchPurposes(),
        fetchOwnerRelations(),
        fetchOwnerTypes(),
      ]).then(([proposals, statusData, purposeData, relationData, ownerTypeData]) => {
        dataReady.value = true;
        data.value = proposals;
        console.log(data.value)
        statusList.value = statusData;
        purposeList.value = purposeData;
        ownerRelationList.value = relationData;
        ownerTypeList.value = ownerTypeData;
      }).catch(err => {
        console.error(err)
      })
    })
    // data.value = fetchProposals();
    // statusList.value = fetchProposalStatus();
    // history.value = fetchProposalStatusHistory();
    // purposeList.value = fetchPurposes();
    // ownerTypeList.value = await fetchOwnerTypes();

    // const getStatusHistory = (proposalId: number) => {

    //   const historys = history.value.filter(h => h.proposal == proposalId).map((h: any) => {
    //     const s = statusList.value.find((s: any) => s.id === h.before_status);
    //     if (s == null) return null;
    //     return {
    //       transition_date: h.transition_date,
    //       status: s
    //     }
    //   }).filter(e => e);
      
    //   return historys;
    // };

    // const getChildren = (s: IProposalStatus) => {
    //   return status.filter((d: any) => d.parent === s.tag);
    // };

    const statusEditingItem = ref<IProposal | null>(null);
    
    
    // const getNextStatuses = (proposalId: number, strStatus: string) => {
    //   const status: IProposalStatus|undefined = statusList.value.find((s: IProposalStatus) => {
    //     return s.name == strStatus
    //   });
    //   console.log(status)
    //   if (status == null) return [];
    //   const children = statusList.value.filter((s: IProposalStatus) => s.parent == status.tag);
    //   return children;
    // };

    // const statusEditingItemNextStatus = computed(() => {
    //   if (statusEditingItem.value == null || statusEditingItem.value.id == null) return null;
    //   return getNextStatuses(statusEditingItem.value.id, statusEditingItem.value.status);
    // });

    const addProposal = () => {
      router.push({ name: 'ProposalsDetailEdit', params: { id: 'new' } })
    }

    const onChangeStatus = async (payload: any) => {
      try {
        const data = await createProposalStatusHistory(payload);
        setMessage({ type: 'success', message: 'ステータスを更新しました。' })
      } catch (err) {
        console.error(err)
        
      }
      
      statusEditingItem.value = null;
      // data.value = await fetchProposals();
      data.value = await fetchProposals();
      //history.value = await fetchProposalStatusHistory()
    }

   

    // const getOwnerTypes = (otList: string[]) => {
      
    //   return otList.map((otStr: string) => {
    //     return ownerTypeList.value.find((ot: IOwnerType) => ot.name == otStr)  
    //   }).filter(e => e)
    // };

    // const getRelations = (reList: string[]) => {
    //   console.log(ownerRelationList.value)
    //   const data = reList.map((orStr: string) => {
    //     const data = ownerRelationList.value.find((or: IOwnerRelation) => or.relation == orStr);
    //     if (data == null ) return null;
    //     return data;
    //   }).filter(e => e).map((d: any)=> ({ name: d.relation, bgcolor: 'gray', fontcolor: 'white' }))
    //   console.log(data)
    //   return data;

    // };

    const getPurpose = (pStr: string) => {
      console.log(pStr)
      const p = purposeList.value.find((p: IPurpose) => p.name == pStr)  
      console.log(p)
      return p;
    }

    const onDelete = async (propsalId: number) => {
      if (!confirm('削除しますか？')) return;
      await deleteProposal(propsalId);
      data.value = await fetchProposals(route.query);
      setMessage({ type: 'success', message: '削除しました。' })
    }

    
    

    return {
      route,
      router,
      profile,
      searchWord,
      formatDate,
      statusQuery,
      dataReady,
      addProposal,
      data,
      displayedData,
      // getStatusHistory,
      statusEditingItem,
      purposeList,
      statusList,
      ownerTypeList,
      onChangeStatus,
      // getOwnerTypes,
      // getRelations,
      getPurpose,
      onDelete,
    }
  }
})
